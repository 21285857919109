import React from 'react';
import styled from 'styled-components';
import DashLine from './DashLine';
import StyledButton from './StyledButton';

const NewsPlaceholder = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 90%;
  ${({ theme }) => theme.media.tablet} {
    width: 70%;
  } ;
`;

const NewsParagraph = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.7rem;
  align-self: center;
  text-align: center;
  margin: 15px;
  ${({ theme }) => theme.media.tablet} {
    font-size: 1.8rem;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 2.4rem;
  }
`;

const NewsHeader = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 2.8rem;
  margin: 0;
  margin-top: 20px;
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 4rem;
  }
`;

const NewsButton = styled(StyledButton)`
  margin: 15px;
  padding: 10px;
  border: 0;
  font-size: 1.6rem;
  background-color: ${({ theme }) => theme.colors.brown};
`;

const ImagePlaceholder = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;
`;
const Image = styled.img`
  border-radius: 40px;
  max-width: 220px;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.media.tablet} {
    max-width: 380px;
  } ;
`;

const NewsBox = ({ heroImage }: any) => {
  const { url } = heroImage.heroImage.file;
  return (
    <NewsPlaceholder
      data-sal="zoom-out"
      data-sal-duration="1000"
      data-sal-easing="ease"
    >
      <NewsHeader>Nowości</NewsHeader>
      <NewsParagraph>
        Regularnie dodajemy nowe pozycje do naszego menu, by jak najbardziej
        urozmaicić wybór naszym klientom.
      </NewsParagraph>
      <ImagePlaceholder>
        <Image src={url} alt="rice" />
      </ImagePlaceholder>
      <DashLine color="#6F0F06" />
      <NewsParagraph>Sprawdź jakie akutalnie mamy nowości!</NewsParagraph>
      <NewsButton align="center" href="/menu/nowosci">
        Sprawdź
      </NewsButton>
    </NewsPlaceholder>
  );
};

export default NewsBox;
