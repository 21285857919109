import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

type Props = {
  children: any;
  href?: string;
  align: string;
  className?: string;
};

const Button = styled(Link)<Props>`
  text-decoration: none;
  min-width: 70px;
  min-height: 40px;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: ${({ align }) => align};
  background-color: ${({ theme }) => theme.colors.darkgreen};
  border-radius: 20px;
  border: 1px solid #707070;
  color: ${({ theme }) => theme.colors.white};
  margin: 5px;
  padding: 5px;
  transition: all 0.4s ease-in-out;
  ${({ theme }) => theme.media.tablet} {
    border-radius: 50px;
    width: 120px;
    height: 45px;
    font-size: 1.5rem;
  }
  ${({ theme }) => theme.media.desktop} {
    :hover {
      color: ${({ theme }) => theme.colors.darkorange};
      text-decoration: none;
      font-size: 1.3rem;
    }
  }
`;
const StyledButton = ({ children, href, align, className }: Props) => (
  <Button align={align} to={href || ''} className={className}>
    {children}
  </Button>
);

export default StyledButton;
