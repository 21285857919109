import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import MobileFanPage from './MobileFanPage';
import DesktopFanPage from './DesktopFanPage';

const FanpagePlaceholder = styled.section`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border: none;
  text-align: center;
`;

const H1 = styled.h1`
  margin: 20px;
  color: ${({ theme }) => theme.colors.brown};
  font-size: 2.2rem;
  ${({ theme }) => theme.media.tablet} {
    font-size: 4rem;
  }
`;

const Fanpage = () => {
  const [, setWindow] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const getWindow = () => {
      if (window) {
        setWidth(window.innerWidth);
        setWindow(true);
      } else {
        setWindow(false);
      }
    };
    getWindow();
  }, []);
  const renderFanPage = () => {
    if (width < 600) {
      return <MobileFanPage />;
    }
    return <DesktopFanPage />;
  };

  return (
    <>
      <FanpagePlaceholder>
        <H1>Odwiedź nas na facebooku!</H1>
        {renderFanPage()}
      </FanpagePlaceholder>
    </>
  );
};

export default Fanpage;
