import { graphql } from 'gatsby';
import React from 'react';
import Fanpage from '../components/FacebookFanpage/Fanpage';
import OfferBox from '../components/OfferBox';
import Layout from '../components/Layout/Layout';
import NewsBox from '../components/NewsBox';

export type Props = {
  data?: any;
};

const Index = ({
  data: {
    allContentfulImages,
    contentfulOfferBoxContent,
    contentfulNewsBoxContent,
  },
}: Props) => {
  const logo = allContentfulImages.edges[0].node.logo.file.url;
  const backgroundDesktop =
    allContentfulImages.edges[0].node.backgrounds[0].file.url;

  return (
    <Layout
      logo={logo}
      backgroundDesktop={backgroundDesktop}
      title="Strona Główna"
      description="Zobacz naszą stronę internetową, zamów jedzenie na wynos, zrób sobie smaka!"
      ogTitle="Strona Główna | Smaki Azji Siemianowice Śląskie"
      ogContent="Smaki Azji w Siemianowicach Śląskich, zamów jedzenie na wynos, lub przyjedź i spróbuj naszych pysznych dań na miejscu!"
    >
      <NewsBox heroImage={contentfulNewsBoxContent} />
      <OfferBox heroImage={contentfulOfferBoxContent} />
      <Fanpage />
    </Layout>
  );
};

export const getImages = graphql`
  query {
    contentfulOfferBoxContent {
      heroImage {
        file {
          url
        }
      }
    }
    contentfulNewsBoxContent {
      heroImage {
        file {
          url
        }
      }
    }
    allContentfulImages {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Index;
