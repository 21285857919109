import React from 'react';
import styled from 'styled-components';

const Line = styled.div`
  border: 1px solid ${({ color }) => color};
  width: 70%;
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

type Props = {
  children?: any;
  color: string;
};

const DashLine = ({ children, color }: Props) => (
  <Line color={color}>{children}</Line>
);

export default DashLine;
