import React from 'react';
import styled from 'styled-components';

import DashLine from './DashLine';
import StyledButton from './StyledButton';

const BoxPlaceholder = styled.section`
  background-color: ${({ theme }) => theme.colors.green};
  margin: 30px;
  padding: 10px 15px;
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 20px;
  align-items: center;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.media.tablet} {
    width: 75%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 70%;
  }
`;

const H1 = styled.h1`
  text-align: center;
  font-size: 2.8rem;
  margin: 0;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.darkgreen};
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 4rem;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  padding: 0 10px;
  ${({ theme }) => theme.media.tablet} {
    width: 80%;
  }
`;

const ArticleBox = styled.article`
  color: ${({ theme }) => theme.colors.white};
  margin: 10px 0;
  font-size: 1.4rem;
  line-height: 1.5;
  ${({ theme }) => theme.media.desktop} {
    font-size: 1.8rem;
  }
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 2rem;
  }
`;

const GrayText = styled.span`
  margin: 10px;
  color: ${({ theme }) => theme.colors.brown};
  display: none;
  font-size: 1.6rem;
  ${({ theme }) => theme.media.tablet} {
    display: block;
  }
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 2rem;
  }
`;
const StyledText = styled.span`
  font-size: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkgreen};
  ${({ theme }) => theme.media.tablet} {
    font-size: 1.5rem;
    margin-right: 30px;
  }
`;

const SpanPlaceholder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.tablet} {
    justify-content: center;
  }
`;

const ImagePlaceholder = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;
`;
const Image = styled.img`
  border-radius: 40px;
  max-width: 220px;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.media.tablet} {
    max-width: 380px;
  } ;
`;

const OfferBox = ({ heroImage }: any) => {
  const { url } = heroImage.heroImage.file;
  return (
    <BoxPlaceholder
      data-sal="zoom-out"
      data-sal-duration="1000"
      data-sal-easing="ease"
    >
      <H1>Nasza oferta</H1>
      <TextWrapper>
        <ArticleBox>
          Organizujemy imprezy okolicznościowe, a także prywatne spotkania jak i
          komunie. Jesteśmy w stanie zaoferować ciekawą ofertę kateringową oraz
          wiele innych.
        </ArticleBox>
        <ImagePlaceholder>
          <Image src={url} alt="rice" />
        </ImagePlaceholder>
        <GrayText>
          Z przyczyn oczywistych oraz aktualnie panujących rozporządzeń, nasz
          repertuar jest dosyć ograniczony.
        </GrayText>
        <ArticleBox>
          W razie jakichkolwiek pytań zachęcamy do odwiedzin w lokalu.
        </ArticleBox>
      </TextWrapper>
      <DashLine color="#3d6b0e" />
      <SpanPlaceholder>
        <StyledText>Zrób sobie smaka!</StyledText>
        <StyledButton align="flex-end" href="/menu">
          Menu
        </StyledButton>
      </SpanPlaceholder>
    </BoxPlaceholder>
  );
};

export default OfferBox;
